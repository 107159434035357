import React from 'react'
import { Box, Grid } from '@mui/material';
import EditorsPick from '../../Components/NewsSessionComponents/EditorsPick';
import Features from '../../Components/NewsSessionComponents/Features';
import LatestSession from '../../Components/NewsSessionComponents/LatestSession';

function NewsSection({ groupedCategory, ads, filteredNews }) {
    const style = {
        marginBottom: "20px"
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={9}>
                <Features groupedCategory={groupedCategory} />
                {/* <EditorsPick /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
                <LatestSession filteredNews={filteredNews} />
                {ads.map((ads, index) => (
                    <Box style={style} key={index}>
                        <img alt="complex" src={ads?.image?.url} style={{ width: '100%', height: '100%', borderRadius: 5 }} />
                    </Box>
                ))}
            </Grid>
        </Grid>
    )
}

export default NewsSection;
import React, { useEffect, useState } from 'react';
import './App.css';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { finishLoadingUser } from "./redux/slices/userLoadingSlice";
import { setUser } from "./redux/slices/userSlice";
import { getAllCategories, getCurrentUser } from './Services/AdminServices';
import UserRoutePages from './Routes/UserRoutes';
import NavBar from './User/Components/Navbar/NavBar';
import TabNavigation from './User/Components/Navbar/TabNavigation';
import ResponsiveDrawer from './Admin/Component/Drawer/Drawer';
import Footer from './User/Components/Footer/Footer';
import { premiumAdds } from './Services/UserServices';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [categories, setCategories] = useState([]);
  const [premiumAds, setPremiumAds] = useState([]);

  useEffect(() => {
    if (sessionStorage.getItem("authtoken")) {
      getCurrentUser().then((response) => {
        dispatch(setUser(response?.data));
        dispatch(finishLoadingUser());
      })
        .catch((err) => {
          sessionStorage.removeItem("authtoken");
          dispatch(finishLoadingUser());
        });
    } else {
      dispatch(finishLoadingUser());
    }
  }, [dispatch]);

  const getCategories = async () => {
    try {
      await getAllCategories().then((res) => {
        let response = res?.data?.categories;
        setCategories(response);
        return premiumAdds();
      }).then((res) => {
        let data = res?.data?.ads;
        setPremiumAds(data);
      })
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  if (location.pathname.includes("/admin")) {
    return (
      <div className="App">
        <ResponsiveDrawer categories={categories} setCategories={setCategories} />
      </div>
    );
  }

  return (
    <div className="App">
      <div className="body-center">
        <div className="body-width">
          <NavBar />
          <TabNavigation categories={categories} premiumAds={premiumAds} />
          <UserRoutePages />
          <div style={{ paddingTop: 30 }}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

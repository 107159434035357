import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Grid, } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getFilteredNewsData } from '../../../Services/UserServices';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

const Img = styled('img')({
  margin: 'auto 0px auto 0px',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  marginBottom: 20
}));

function Latestnews({ news }) {

  const ArticlesButton = {
    backgroundColor: '#3a98c9',
    fontFamily: 'Noto Sans Malayalam, sans-serif',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '1.5em',
    margin: '20px',
  }

  const boxStyle2 = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingRight: "10px",
    paddingLeft: "10px",
    paddingBottom: "10px"
  }

  const boxStyleSecondWithBorder = {
    display: 'flex',
    flexDirection: 'row',
    gap: "10px",
    borderTop: '1px solid #e5e5e5',
    marginTop: '15px',
  }

  const navigate = useNavigate();
  const [filteredNews, setFilteredNews] = useState([]);

  const getData = async () => {
    let catName = news?.categoryName ? news?.categoryName : "";
    let type = news?.type ? news?.type : "";
    let filter = {
      "categoryName": catName,
      "type": type,
      "count": 4
    }
    await getFilteredNewsData(filter).then((res) => {
      let data = res?.data?.latestNews;
      setFilteredNews(data);
    }).catch((err) => { console.log("Error:", err) })
  }

  useEffect(() => {
    getData();
  }, []);

  const navigateNewsDetails = (id) => {
    navigate(`/user/news/details/${id}`);
  }

  const loadNews = (latestNews) => {
    return (latestNews?.map((latest, index) => {
      const renderFilteredContent = (htmlContent) => {
        let arr = [];
        parse(htmlContent, {
          replace: (domNode) => {
            arr.push(domNode);
            return null;
          },
        });

        const imageNode = arr.find((node) => node?.name === 'img');
        const descriptionNode = arr.find((node) => node?.type === "text");

        const image = imageNode ? (
          <img
            alt="News"
            src={imageNode?.attribs?.src || '/DefaultImages/news_default_img.jpeg'}
            style={{ marginTop: '10px', width: '85px', height: '85px', objectFit: 'cover' }}
          />
        ) : (
          <img
            alt="Default"
            src="/DefaultImages/news_default_img.jpeg"
            style={{ marginTop: '10px', width: '85px', height: '85px', objectFit: 'cover' }}
          />
        );

        const description = descriptionNode ? descriptionNode?.data : '';

        return { image, description };
      };

      const { image, description } = renderFilteredContent(latest?.content);
      return (
        <Box style={boxStyleSecondWithBorder} key={index}>
          {image}
          <Box>
            <p className="hover-blue-title-text-header" dangerouslySetInnerHTML={{ __html: latest?.title }} onClick={() => { navigateNewsDetails(latest?.slug) }}>
            </p>
            <p className='description-text-style'>{description}</p>
          </Box>
        </Box>
      )
    }))
  }

  return (filteredNews.length !== 0 && (
    <Item>
      <Box>
        <Button variant="contained" style={ArticlesButton}>Similar News</Button>
      </Box>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={9} md={12} lg={12}>
          <Box sx={boxStyle2}>
            {loadNews(filteredNews)}
          </Box>
        </Grid>
      </Grid>
    </Item>
  ))
}

export default Latestnews;
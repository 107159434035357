import React, { useEffect, useState } from 'react'
import { Box, Card, Fab, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Select, Toolbar, Popover, Dialog, DialogTitle, DialogContent, TextField, Icon } from '@mui/material';
import styled from '@emotion/styled';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ArticleIcon from '@mui/icons-material/Article';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InfoIcon from '@mui/icons-material/Info';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CampaignIcon from '@mui/icons-material/Campaign';
import DraftsIcon from '@mui/icons-material/Drafts';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { deleteNewsById, deleteNewsBySlugId, getAdsCount, getAllCategories, getAllNews, getDraftCount, getNewsCount } from '../../../Services/AdminServices';
import moment from 'moment';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import '../../../Styles/News.css'
import { MenuProps } from '../../../Components/materialUiSelectStyle/materialUiSelectStyle';
import Loader from '../../../Components/Loader/Loader';
import PropTypes from 'prop-types';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { alpha } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { FacebookIcon, WhatsappIcon } from 'react-share';
import TablePaginationActions from '../../../Components/TablePagination/tablePagination';
import ShareIcon from '@mui/icons-material/Share';
import parse from 'html-react-parser';

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'dateAdded') {
    return new Date(b.date) - new Date(a.date);
  }
  if (orderBy === 'category') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  if (orderBy === 'post') {
    if (b.title < a.title) {
      return -1;
    }
    if (b.title > a.title) {
      return 1;
    }
    return 0;
  }
  if (orderBy === 'postType') {
    if (b.type < a.type) {
      return -1;
    }
    if (b.type > a.type) {
      return 1;
    }
    return 0;
  }
  if (orderBy === 'author') {
    if (b.author.firstname ? b.author.firstname : b.author.email < a.author.firstname ? a.author.firstname : a.author.email) {
      return -1;
    }
    if (b.author.firstname ? b.author.firstname : b.author.email > a.author.firstname ? a.author.firstname : a.author.email) {
      return 1;
    }
    return 0;
  }
  if (orderBy === 'pageView') {
    if (b.viewsCount < a.viewsCount) {
      return -1;
    }
    if (b.viewsCount > a.viewsCount) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'post',
    label: 'News',
  },
  {
    id: 'postType',
    label: 'Type',
  },
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'author',
    label: 'Author',
  },
  {
    id: 'pageView',
    label: 'Pageview',
  },
  {
    id: 'dateAdded',
    label: 'Date',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <span className='table-header'>
                {headCell.label}
              </span>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, setDeleteDialog } = props;

  const deleteDialogOpen = async () => {
    setDeleteDialog(true);
  }

  return (
    <Toolbar
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {numSelected > 0 ?
        <IconButton onClick={() => { deleteDialogOpen() }} title="Delete All Selected News">
          <DeleteIcon color='error' />
        </IconButton>
        : ""}
    </Toolbar >
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  marginBottom: 20,
  padding: 20
}));

function Dashboard({categories}) {

  const navigate = useNavigate();

  // States
  const [news, setNews] = useState([]);
  // const [categories, setCategories] = useState(null);
  const [category, setCategory] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  // Sorting Data Table
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('dateAdded');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  // const [catCount, setCatCount] = useState(0);
  const [newsCount, setNewsCount] = useState(0);
  const [adsCount, setAdsCount] = useState(0);
  const [draftCount, setDraftCount] = useState(0);

  // Delete Dialog
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteOneDialog, setDeleteOneDialog] = useState(false);
  const [newsId, setNewsId] = useState("");

  // Loader
  const [loading, setLoading] = useState(false);

  // Popper Share
  const [anchorEl, setAnchorEl] = useState(null);
  const [shareContent, setShareContent] = useState(null);

  const handleShareClick = (event, news) => {
    setAnchorEl(event.currentTarget);
    setShareContent(news);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Category Change
  const categoryChange = (e) => {
    setCategory(e.target.value);
  }

  // Search Filter
  const filteredCategories = news ? news.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (!category || item?.category === category)
  ) : [];

  // Get datas From api
  const getData = async () => {
    try {
      setLoading(true);
      await getNewsCount().then((res) => {
        setNewsCount(res?.data?.count);
        return getAdsCount();
      }).then((res) => {
        setAdsCount(res?.data?.count);
        return getDraftCount();
      }).then((res) => {
        setDraftCount(res?.data?.count);
        return getAllNews();
      }).then((res) => {
        let data = res?.data?.news;
        setNews(data);
        setLoading(false);
      })
    } catch (err) {
      setLoading(false);
      console.log("Error:", err)
    }
  }

  useEffect(() => {
    getData();
  }, []);


  // Load first Image
  const loadImage = (content, newsId, titl, news) => {

    const renderFilteredContent = (htmlContent) => {
      let arr = [];
      parse(htmlContent, {
        replace: (domNode) => {
          arr.push(domNode);
          return null;
        },
      });

      const imageNode = arr.find((node) => node?.name === 'img');

      const imageSrc = imageNode
        ? imageNode.attribs.src
        : news?.categoryName === "Matrimony"
          ? "/DefaultImages/matrimony_default_img.jpeg"
          : "/DefaultImages/news_default_img.jpeg";

      const image = (
        <PhotoProvider>
          <PhotoView src={imageSrc}>
            <img
              alt="profile"
              src={imageSrc}
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '0',
                cursor: 'pointer',
                objectFit: 'cover',
              }}
            />
          </PhotoView>
        </PhotoProvider>
      );

      return { image };
    };

    const { image } = renderFilteredContent(content);

    return (
      <Box className="post-avatar-flex">
        {image}
        <div
          onClick={() => navigate(`/user/news/details/${newsId}?status=edit-status`)}
          className="news-name-style-title"
        >
          {titl}
        </div>
      </Box>
    );
  };

  // ShareOption
  const whatsAppShare = (news) => {
    let localHost = window.location.origin;

    let link = `${localHost}/user/news/details/${news?.slug}`;

    const whatsappLink = `https://wa.me/?text=${encodeURIComponent(link)}`;
    // Open WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  }

  const facebookShare = (news) => {
    let localHost = window.location.origin;

    let link = `${localHost}/user/news/details/${news?.slug}`;

    const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=&quote=${encodeURIComponent(link)}`;
    // Open Facebook share link in a new tab
    window.open(facebookLink, '_blank');
  }

  // Delete News
  const deleteDialogCancel = () => {
    setDeleteDialog(false);
    setSelected([]);
  }

  const deleteNews = async (ids) => {
    try {
      await Promise.all(ids.map(async (id) => {
        await deleteNewsById(id);
      }));
      toast.success('Selected News articles deleted successfully');
      await new Promise(resolve => setTimeout(resolve, 2000));
      await getData();
      deleteDialogCancel();
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }

  const deleteOneDialogOpen = (id) => {
    setNewsId(id);
    setDeleteOneDialog(true);
  }

  const deleteOneDialogCancel = () => {
    setNewsId("");
    setDeleteOneDialog(false);
    setSelected([]);
  }

  const deleteOneNews = async (id) => {
    try {
      await deleteNewsBySlugId(id).then((res) => {
        toast.success(res?.data?.message);
        setTimeout(() => {
          getData();
          deleteOneDialogCancel();
        }, 2000);
      })
    } catch (err) {
      toast.error(err.response.data.message);
    }
  }

  // Sorting Data Table
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Select Row
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = filteredCategories.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  // Pagination and load data
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredCategories, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredCategories, order, orderBy, page, rowsPerPage]
  );

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <Box>
        <Typography variant="h4" gutterBottom>
          Dashboard
        </Typography>
      </Box>
      <Box sx={{ mb: 5 }}>
        <Card sx={{ boxShadow: 'none', borderRadius: 5, p: 3 }}>
          <Box className="section-overview-header-flex">
            <div className='overview-header-first-icon'></div>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Overview
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Box sx={{ backgroundColor: "#E9F6FC", borderRadius: 3, p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <NewspaperIcon sx={{ fontSize: 48, color: '#0288d1', mr: 1 }} />
                  <Typography variant="h6">News in this month</Typography>
                </Box>
                <Typography variant="h3" component="div" gutterBottom>
                  {newsCount}
                </Typography>


                <Button
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  sx={{
                    textTransform: 'none',
                    backgroundColor: '#0288d1',
                    '&:hover': { backgroundColor: '#01579b' },
                    borderRadius: 2,
                    px: 3,
                    py: 1
                  }}
                  onClick={() => { navigate(`/admin/new-news`) }}
                >
                  Create News
                </Button>


              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box sx={{ backgroundColor: "#F0ECFD", borderRadius: 3, p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <CampaignIcon sx={{ fontSize: 48, color: '#7c4dff', mr: 1 }} />
                  <Typography variant="h6">Ads in this month</Typography>
                </Box>
                <Typography variant="h3" component="div" gutterBottom>
                  {adsCount}
                </Typography>

                <Button
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  sx={{
                    textTransform: 'none',
                    backgroundColor: '#7c4dff',
                    '&:hover': { backgroundColor: '#6200ea' },
                    borderRadius: 2,
                    px: 3,
                    py: 1
                  }}
                  onClick={() => { navigate(`/admin/new-ads`) }}
                >
                  Create Ad
                </Button>


              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box sx={{ backgroundColor: "#EAF6EF", borderRadius: 3, p: 3 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <DraftsIcon sx={{ fontSize: 48, color: '#4caf50', mr: 1 }} />
                  <Typography variant="h6">Drafts in this month</Typography>
                </Box>
                <Typography variant="h3" component="div" gutterBottom>
                  {draftCount}
                </Typography>

                <Button
                  variant="contained"
                  startIcon={<VisibilityIcon />}
                  sx={{
                    textTransform: 'none',
                    backgroundColor: '#4caf50',
                    '&:hover': { backgroundColor: '#2e7d32' },
                    borderRadius: 2,
                    px: 3,
                    py: 1
                  }}
                  onClick={() => { navigate(`/admin/drafted-news`) }}
                >
                  View Drafts
                </Button>


              </Box>
            </Grid>


          </Grid>
        </Card>
      </Box>
      <Box>
        <TableContainer component={Item}>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
            <Box className="section-news-header-flex">
              <div className='overview-header-second-icon'></div>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                News
              </Typography>
              <TextField
                placeholder="Search..."
                size='small'
                sx={{ backgroundColor: '#F4F4F4' }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon>
                        <SearchIcon />
                      </Icon>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box className="news-actions-flex">
              <Box>
                <FormControl fullWidth sx={{ width: 150 }}>
                  <Select
                    size='small'
                    MenuProps={MenuProps}
                    sx={{
                      backgroundColor: '#F4F4F4',
                      '& .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { color: category === "" ? 'grey' : 'black' },
                      '& .css-182didf.MuiSelect-select': { color: category === "" ? 'grey' : 'black' },
                    }}
                    value={category}
                    displayEmpty
                    onChange={(e) => { categoryChange(e) }}
                  >
                    <MenuItem value="" disabled={true} sx={{ display: 'none' }}>Category</MenuItem>
                    {categories && categories.map((cat, i) => (
                      <MenuItem key={i} value={cat._id}>{cat?.categoryName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Button variant='contained' style={{ backgroundColor: "#2A85FF", color: "white", borderRadius: 10 }} onClick={() => { navigate(`/admin/new-news`) }}><AddIcon />Create</Button>
              </Box>
            </Box>
          </Box>
          <Table
            sx={{ minWidth: 650 }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredCategories.length}
            />
            <TableBody>
              {visibleRows.map((news, index) => {
                const isItemSelected = isSelected(news._id);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={news._id}
                    selected={isItemSelected}
                  >
                    <TableCell align="left">
                      {loadImage(news?.content, news.slug, news?.title, news)}
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        marginTop: '8px',
                        marginLeft: '4px'

                      }}>
                        <IconButton onClick={(e) => { handleShareClick(e, news) }} title='Share News'  >
                          <ShareIcon style={{ fontSize: '14px' }} />
                        </IconButton>
                        <IconButton title="Edit News" onClick={() => { navigate(`/admin/edit-news/${news?.slug}`) }}  >
                          <EditIcon style={{ fontSize: '14px' }} />
                        </IconButton>
                        <IconButton title="Delete News" onClick={() => { deleteOneDialogOpen(news?.slug) }}  >
                          <DeleteIcon style={{ fontSize: '14px' }} />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <span className='news-name-style'>
                        {news?.type}
                      </span>
                    </TableCell>
                    <TableCell align="left">
                      <span className='news-name-style'>
                        {news?.categoryName}
                      </span>
                    </TableCell>
                    <TableCell align="left">
                      <span className='news-name-style'>
                        {news?.author?.firstname ? news?.author?.firstname : news?.author?.email}
                      </span>
                    </TableCell>
                    <TableCell align="left">{news?.viewsCount}</TableCell>
                    <TableCell align="left">
                      <span className='news-name-style'>
                        {moment(news?.date).format('DD-MM-YYYY')}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
              {visibleRows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10} align="center" sx={{ fontWeight: 'bold', fontSize: 20 }}>No Records Found...</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50, 75, 100]}
          component="div"
          count={filteredCategories.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          sx={{ color: 'rgb(42, 133, 255)' }}
        />
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={deleteDialog}
        onClose={() => deleteDialogCancel()}
      >
        <DialogTitle>
          Are you sure you want to remove the selected news?
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", justifyContent: "center", gap: 2, flexDirection: 'wrap' }}>
            <Button variant="contained" color='primary' sx={{ fontSize: 15 }} onClick={() => { deleteNews(selected) }}>YES</Button>
            <Button color='error' sx={{ fontSize: 15 }} onClick={deleteDialogCancel} >NO</Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={deleteOneDialog}
        onClose={() => deleteOneDialogCancel()}
      >
        <DialogTitle>
          Are you sure you want to remove this news?
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex", justifyContent: "center", gap: 2, flexDirection: 'wrap' }}>
            <Button variant="contained" color='primary' sx={{ fontSize: 15 }} onClick={() => { deleteOneNews(newsId) }}>YES</Button>
            <Button color='error' sx={{ fontSize: 15 }} onClick={deleteOneDialogCancel} >NO</Button>
          </div>
        </DialogContent>
      </Dialog>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px', gap: '20px' }}>
          <WhatsappIcon size={32} round={true} style={{ cursor: 'pointer' }} onClick={() => { whatsAppShare(shareContent) }} />
          <FacebookIcon size={32} round={true} style={{ cursor: 'pointer' }} onClick={() => { facebookShare(shareContent) }} />
        </div>
      </Popover>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            style: {
              background: 'rgb(46, 125, 50)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(46, 125, 50)',
              secondary: 'white',
            },
          },
          error: {
            style: {
              background: 'rgb(211, 47, 47)',
              color: 'white'
            },
            iconTheme: {
              primary: 'rgb(211, 47, 47)',
              secondary: 'white',
            },

          },
        }}
      />
    </div>
  )
}

export default Dashboard;
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Collapse, Drawer, List, ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { removeFromLocalStorage } from '../../../Helper/helper';

function NavBar({ drawerMenu, setDrawerMenu,categories }) {
  const navigate = useNavigate()

  const handleCloseNavMenu = () => {
    setDrawerMenu(false);
  };

  // Home
  const handleHomeClick = () => {
    removeFromLocalStorage(`/`);
    navigate(`/`);
    setTopicsMenu(false);
    setNewsMenu(false);
    setProductsMenu(false);
    handleCloseNavMenu();
  };

  // News
  const [newsMenu, setNewsMenu] = useState(false);
  const handleNewsClick = () => {
    setNewsMenu(!newsMenu);
    setTopicsMenu(false);
    setProductsMenu(false);
  };

  // Resources
  const handleResourceClick = () => {
    removeFromLocalStorage(`/user/matrimony`);
    navigate(`/user/matrimony`);
    setTopicsMenu(false);
    setNewsMenu(false);
    setProductsMenu(false);
    handleCloseNavMenu();
  };

  // Products
  const [productsMenu, setProductsMenu] = useState(false);
  const handleProductsClick = () => {
    setProductsMenu(!productsMenu);
    setTopicsMenu(false);
    setNewsMenu(false);
  };

  // Topics
  const [topicsMenu, setTopicsMenu] = useState(false);
  const handleTopicsClick = () => {
    setTopicsMenu(!topicsMenu);
    setNewsMenu(false);
    setProductsMenu(false);
  };

  // About
  const handleAboutClick = () => {
    navigate(`/about-us`);
    setTopicsMenu(false);
    setNewsMenu(false);
    setProductsMenu(false);
    handleCloseNavMenu();
  };

  const navigateNewsListOfCategory = (news) => {
    if (news?.categoryName == 'Matrimony') {
      removeFromLocalStorage(window.location.pathname);
      navigate(`/user/matrimony`);
    } else if (news?.categoryName == 'Obituary') {
      removeFromLocalStorage(`/user/obituary`);
      navigate(`/user/obituary`);
    } else {
      removeFromLocalStorage(`/user/news/category/list`);
      navigate(`/user/news/category/list`, {
        state: {
          category: news?.categoryName,
          type: news?.type,
        }
      });
    }
    setTopicsMenu(false);
    setNewsMenu(false);
    setProductsMenu(false);
    handleCloseNavMenu();
  }

  return (
    <div>
      <Drawer
        anchor={"left"}
        open={drawerMenu}
        onClose={handleCloseNavMenu}
        sx={{ '& .MuiDrawer-paper': { backgroundColor: '#1C2365 !important', color: 'white !important' } }}>
        <Box
          style={{ width: 250 }}
          role="presentation">
          <List>
            <ListItemButton onClick={() => { handleHomeClick() }}>
              <ListItemText primary="Home" />
            </ListItemButton>
            <ListItemButton onClick={handleNewsClick}>
              <ListItemText primary="News and Views" />
              {newsMenu ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </ListItemButton>
            <Collapse in={newsMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {categories && categories.map((cat, index) => (['Kerala', 'National', 'International', 'Obituary', 'Article & Editorial'].includes(cat?.categoryName) &&
                  <ListItemButton sx={{ pl: 4 }} key={index} onClick={() => { navigateNewsListOfCategory(cat) }}>
                    <ListItemText primary={cat?.categoryName} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
            <ListItemButton onClick={handleResourceClick}>
              <ListItemText primary="Matrimony" />
            </ListItemButton>
            <ListItemButton onClick={handleProductsClick}>
              <ListItemText primary="Classifieds" />
              {productsMenu ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </ListItemButton>
            <Collapse in={productsMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {categories && categories.map((cat, index) => (['Buy & Sell', 'Rentals', 'Real Estate', 'Vehicles'].includes(cat?.categoryName) &&
                  <ListItemButton sx={{ pl: 4 }} key={index} onClick={() => { navigateNewsListOfCategory(cat) }}>
                    <ListItemText primary={cat?.categoryName} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
            <ListItemButton onClick={handleTopicsClick}>
              <ListItemText primary="Topics" />
              {topicsMenu ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </ListItemButton>
            <Collapse in={topicsMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {categories && categories.map((cat, index) => (['Kidz', 'Youth', 'Women', 'Success Stories'].includes(cat?.categoryName) &&
                  <ListItemButton sx={{ pl: 4 }} key={index} onClick={() => { navigateNewsListOfCategory(cat) }}>
                    <ListItemText primary={cat?.categoryName} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
            <ListItemButton onClick={handleAboutClick}>
              <ListItemText primary="About Us" />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>
    </div>
  )
}

export default NavBar;